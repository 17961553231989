import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';


export default function ModalViewSkill({grade_skill_arr, teacher_notes}){
	var base = new Base()

	return(
		<div className="modal fade" id="viewSkillModal" tabIndex="-1" aria-labelledby="viewSkillModalLabel" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content rounded border-0 shadow-sm">
					<div className="modal-body p-0">
						<div className={'row m-0'}>
							<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
							<div className='col-12 p-3 pt-4 pb-5'>
								<div className='row m-0'>
									<div className='col-12 mb-3'>
										<h5 className='m-0'><i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}></i>Grade Skill</h5>
									</div>

									<div className='col-12 mt-3'>
										<div className='row m-0'>
											<div className='col-12'>
												<div className='row'>
													<div className='col-12'>                                                                
														<div className='table-responsive'>
															<table class="table table-striped table-borderless">
																<thead>
																	<tr>
																		<th colSpan={2}>Grade Skill</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		grade_skill_arr.map((data, index)=>(
																			<>
																				<tr>
																					<td colSpan={2}>{data.name}</td>
																				</tr>
																				{
																					data.arr_skill.map((data_skill, index_skill)=>(
																						<tr>
																							<td className='align-middle'>
																								<p className='m-0'>{index_skill + 1}. {data_skill.name}</p>
																							</td>
																							<td className='w-25'>
																								<p className='m-0 text-right'>{data_skill.score}</p>
																							</td>
																						</tr>
																					))
																				}
																			</>
																		))
																	}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='col-12 mt-4'>
										<div className='row m-0'>
											<div className='col'>
												<button className='btn btn-outline-primary rounded px-5' data-dismiss="modal">Close</button>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}