import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';

// import {Calendar} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import HomeList from '../../components/homeList';
import LessonBadge from '../../components/lessonBadge';
import LoadingData from '../../components/loading';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';



export default function HomeIndex(){
	var base = new Base()

	const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_class : {id : ''}, current_academic_year : null})
	const [is_student_access, set_is_student_access] = useState(false)

	const [schedule_data, set_schedule_data] = useState([])

	const [announcement_arr, set_announcement_arr] = useState([])
	const [to_do_list_arr, set_to_do_list_arr] = useState([])

	const [lesson_tracker_btn_arr] = useState([
		{icon : 'fas fa-chevron-circle-left', type : 'prev', margin : 'mr-2'},
		{icon : 'fas fa-chevron-circle-right', type : 'next', margin : 'ml-2'},
	])

	const [lessonDate, setLessonDate] = useState([])

	const [schedule_arr, set_schedule_arr] = useState([])

	const [schedule_lesson_day_arr, set_schedule_lesson_day_arr] = useState([])
	const [schedule_lesson_time_arr, set_schedule_lesson_time_arr] = useState([])
	const [schedule_total_page, set_schedule_total_page] = useState('0')

	const [tracker_lesson_agreement_arr, set_tracker_lesson_agreement_arr] = useState({})
	const [tracker_lesson_arr, set_tracker_lesson_arr] = useState({})
	
	const [tracker_lesson_subject_arr, set_tracker_lesson_subject_arr] = useState([])
	const [tracker_lesson_period, set_tracker_lesson_period] = useState('')
	const [tracker_lesson_name, set_tracker_lesson_name] = useState('')

	const [tracker_counter, set_tracker_counter] = useState(0)
	const [month_tracker, set_month_tracker] = useState('')
	const [tracker_prev, set_tracker_prev] = useState(true)
	const [tracker_next, set_tracker_next] = useState(true)

	const [is_all_schedule, set_is_all_schedule] = useState(false)
	const [schedule_page, set_schedule_page] = useState('0')

	const [is_loading_data, set_is_loading_data] = useState(true)

	const [indicator_tracker] = useState([
		{title : 'Done', color : '#7BBE74'},
		{title : 'A lot of Correction Needed', color : '#CF91FF'},
		{title : 'Tiny Correction Needed', color : '#FF973C'},
		{title : 'Teacher Check', color : '#5EB2FF'},
	])

	const [announcement_selected, set_announcement_selected] = useState({id : '', title : '', body : ''})

	useEffect(async ()=>{
		var check_user = await base.checkAuth()
		set_user_data(check_user.user_data)
		set_is_student_access(check_user.user_data.is_student_access)

		// var check_student_access_date = await base.check_student_access_date()
		// set_is_student_access(check_student_access_date)

		base.$('#modalAnnouncement').on('hidden.bs.modal', function (event) {
			set_announcement_selected({id : '', title : '', body : ''})
		})

	}, [])
	
	useEffect(async()=>{
		if(user_data.current_academic_year != null){
			if(is_student_access){
				get_home_list('announcement')
				// get_home_list('todo')
				set_data_lesson_tracker()
				get_schedule()
				set_schedule_page('1')
			}
		}
		else{
			set_is_student_access(false)
		}
	}, [user_data, is_student_access])

	useEffect(()=>{
		if(announcement_selected.id != ''){
			base.$('#modalAnnouncement').modal('show')
		}
	}, [announcement_selected])

	useEffect(async ()=>{
		// set_schedule_lesson_time_arr([])
		if(schedule_page != 0){
			set_schedule_arr([])
			get_schedule()
		}
	}, [schedule_page])

	useEffect(async()=>{
		if(is_student_access){
			set_tracker_lesson_arr({})
			set_tracker_lesson_agreement_arr({})
			set_data_lesson_tracker()
		}
	}, [tracker_counter])

	async function get_home_list(type){
		var url = '/' + type + '?type=' + (type === 'announcement' ? 'for_student_teacher' : type === 'todo' ? 'today' : '') + '&num_data=4'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var data = response.data.data
				if(type === 'announcement'){
					set_announcement_arr(data)
				}
				else if(type === 'todo'){
					for(var x in data){
						data[x].title = data[x].todo
					}
					set_to_do_list_arr(data)
				}
			}
		}
	}

	function lessonTrackerBtn(type){
		if(type === 'prev'){
			if(tracker_prev){
				set_tracker_counter(tracker_counter-1)
			}
		}
		else if(type === 'next'){
			if(tracker_next){
				set_tracker_counter(tracker_counter+1)
			}
		}
	}

	async function set_data_lesson_tracker(){
		var w = base.$(window).width()
		var num_data = 2
		if(w < 576){
			num_data = 1
		}
		var url = '/class/student-tracker?counter=' + tracker_counter + '&num_data=' + num_data
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data
				var subject_arr = []
				var subject = response_data.arr_subject
				
				for(var x in subject){
					var subject_color_arr = ['purple', 'red', 'yellow']
					var result_color = ''
	
					for(var y in subject_color_arr){
						result_color = subject_color_arr[Math.floor(Math.random() * subject_color_arr.length)]
					}
					subject_arr.push({id : subject[x].id, name : subject[x].name, type : result_color})
				}

				set_tracker_lesson_subject_arr(subject_arr)

				var lesson_schedule = response_data.arr_lesson_schedule.arr
				var lessonDate_arr = []
				for(var x in lesson_schedule){
					var day_name = base.moment(lesson_schedule[x].date).format('dddd, DD')
					lessonDate_arr.push({id : lesson_schedule[x].id, day_name : day_name, day_name_sm : base.moment(lesson_schedule[x].date).format('ddd'), date : base.moment(lesson_schedule[x].date).format('DD'), lesson : lesson_schedule[x].lesson.name, lesson_id : lesson_schedule[x].lesson.id})
				}

				var period = (lesson_schedule.length > 0 ? base.moment(lesson_schedule[0].date).format('MMMM DD') + ' - ' + base.moment(lesson_schedule[0].date).add(5, 'd').format('DD') : base.moment().format('MMMM'))
				var month = (lesson_schedule.length > 0 ? base.moment(lesson_schedule[0].date).format('MMMM') : base.moment().format('MMMM'))

				set_month_tracker(month)
				set_tracker_lesson_period(period)
				set_tracker_lesson_name(lesson_schedule[x].lesson.name)

				setLessonDate(lessonDate_arr)

				var assignment_agreement = response_data.arr_assignment_agreement
				var assignment_submitted = response_data.arr_assignment_submitted

				set_tracker_lesson_arr(assignment_submitted)				
				set_tracker_lesson_agreement_arr(assignment_agreement)

				set_tracker_prev(response_data.arr_lesson_schedule.previous_page)
				set_tracker_next(response_data.arr_lesson_schedule.next_page)

				setTimeout(() => {
					set_is_loading_data(false)
				}, 1000);
			}
		}
	}

	async function get_schedule(){
		var set_day = base.moment().days()
		var w = base.$(window).width()
		var num_data = 6
		if(w < 576){
			if(schedule_page === '2'){
				num_data = 12
			}
		}

		if(set_day != 6 && set_day != 7){
			var url = '/schedule?class_id=' + user_data.current_class.id + '&day=' + set_day + '&num_data=' + num_data
			if(w > 576){
				url += '&page=' + schedule_page
			}
	
			var response = await base.request(url)
			if(response != null){
				if(response.status == 'success'){
					var data = response.data.data
					for(var x in data){
						data[x].time_format = base.moment(data[x].start_time).format('HH:mm') + ' - ' + base.moment(data[x].end_time).format('HH:mm')
	
						data[x].is_now = false
	
						var current_time = base.moment()
						var start_time = base.moment(data[x].start_time_format, 'HH:mm')
						var end_time = base.moment(data[x].end_time_format, 'HH:mm')
						
						if(base.moment(current_time).isSameOrAfter(start_time) && base.moment(current_time).isSameOrBefore(end_time)){
							data[x].is_now = true
						}
					}
					set_schedule_arr(data)
					set_schedule_total_page(response.data.last_page)
				}
			}
		}
	}

	async function showMoreSchedule(){
		if(schedule_page === '1'){
			set_schedule_page('2')
		}
		else{
			set_schedule_page('1')
		}
	}

	async function toDetailActivity(index_subject, index_tracker, index_assignment){
		var id = tracker_lesson_agreement_arr[lessonDate[index_tracker].lesson_id][tracker_lesson_subject_arr[index_subject].id][index_assignment].id
		var type = tracker_lesson_agreement_arr[lessonDate[index_tracker].lesson_id][tracker_lesson_subject_arr[index_subject].id][index_assignment].type
		window.location.href = '/lessons/detail?id=' + id + '&type=' + type
	}

	async function selectAnnouncement(index){
		set_announcement_selected(announcement_arr[index])
	}

	return(
		<div className='row'>

			<div className='col-12'>
				<Header title={'dashboard'} user_data={user_data} announcement_arr={announcement_arr} is_student_access={is_student_access} selectAnnouncement={(index)=>selectAnnouncement(index)} />
			</div>

			{
				is_loading_data ?
				<LoadingData />
				:
				<>
					{
						!is_student_access ?
						<div className='col-12 mt-5 pt-5'>
							<div className='row m-0'>
								<div className='col-12 mt-4 p-0 pb-5 rounded' style={{backgroundColor : '#F8F9FE'}}>
									<div className='row m-0'>
										<div className='col-12 text-center studentNoAccess' style={{marginTop : '-5rem'}}>
											<img src={base.img_study_1} />
											<h2 className='mt-3' style={{color : '#575A89'}}>Take it Easy, Have Fun</h2>
											<p className='m-0' style={{color : '#413F3F'}}>The Academic Year have not yet started. Please come back again after the date due</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<>
							<div className='col-12 mt-3'>
								<div className={'row'}>
									<div className='col-12 p-3 pt-4'>
										<div className='row'>
											<div className='col-12'>
												<div className="table-responsive bg-primary p-3 rounded">
													<table className="table rounded m-0">
														<thead>
															<tr>
																<td className='border-0 pl-0 pr-2 pl-lg-3 pr-lg-3 align-middle td-fit-content' style={{color : '#8A92A6'}}>
																	<p className='m-0 text-white' style={{fontFamily : 'PoetsenOne', fontSize : '1.25rem'}}>{month_tracker}</p>
																</td>

																{
																	lessonDate.length > 0 &&
																	<td colSpan={2} className='bg-white border-0 px-0' style={{borderTopLeftRadius : '.75rem', borderTopRightRadius : '.75rem'}}>
																		<div className='row m-0'>
																			{
																				lessonDate.map((day_data, day_index)=>(
																					<div className='col-12 col-md-6 text-center border-0 bg-white px-2' style={{color : '#8A92A6', fontWeight : 'normal'}} key={day_index}>
																						<div className='row'>
																							{
																								day_index === 0 &&
																								<div className='col-4 col-lg-auto'>
																									<button className='btn btn-primary shadow-sm rounded  d-flex align-items-center justify-content-center lessonTracker_btn' type='button' onClick={()=>lessonTrackerBtn('prev')} disabled={!tracker_prev}><i className='fas fa-chevron-left'></i></button>
																								</div>
																							}
																							<div className='col-4 col-lg trackerLessonTitle'>
																								<div className='row'>
																									<div className='col-auto d-flex align-items-center'>
																										<div className='bg-secondary rounded d-flex justify-content-center align-items-center lessonTracker_btn'>
																											<p className='m-0 text-white' style={{fontFamily : 'BlackHanSans'}}>{day_data.lesson.replace('Lesson', '')}</p>
																										</div>
																									</div>
																									<div className='col-auto d-flex align-items-center p-0'>
																										<p className='m-0 trackerDayName' style={{fontFamily : 'PoetsenOne'}}>{day_data.day_name}</p>
																									</div>
																								</div>
																							</div>

																							{
																								day_index === lessonDate.length-1 &&
																								<div className='col-4 col-lg-auto d-flex justify-content-end'>
																									<button className='btn btn-primary shadow-sm rounded d-flex align-items-center justify-content-center lessonTracker_btn' type='button' onClick={()=>lessonTrackerBtn('next')} disabled={!tracker_next}><i className='fas fa-chevron-right'></i></button>
																								</div>
																							}
																						</div>
																					</div>
																				))
																			}
																		</div>
																	</td>
																}
															</tr>
														</thead>
														<tbody>
															{
																tracker_lesson_subject_arr.map((data_subject, index_subject)=>(
																	<tr key={index_subject}>
																		<td className='pl-0 pr-2 pl-lg-3 pr-lg-5 border-0 td-fit-content'>
																			<p className='m-0 text-white'>{data_subject.name}</p>
																		</td>

																		{
																			lessonDate.length > 0 ?
																			<td colSpan={2} className='p-0 bg-white' style={{borderBottomLeftRadius : (index_subject === tracker_lesson_subject_arr.length-1 ? '.75rem' : ''), borderBottomRightRadius : (index_subject === tracker_lesson_subject_arr.length-1 ? '.75rem' : '')}}>
																			<div className='row m-0'>
																			{
																				lessonDate.map((data_tracker, index_tracker)=>(
																					<div className='col-12 col-md-6 m-0 text-center bg-white py-2 px-1' key={index_tracker} >
																						<div className='row m-0'>
																						{
																							tracker_lesson_agreement_arr[data_tracker.lesson_id] != null &&
																							<>
																							{
																								tracker_lesson_agreement_arr[data_tracker.lesson_id][data_subject.id].map((data_assignment, index_assignment)=>(
																									<>
																									<div className={'col-12 col-lg-6 px-1' + (index_assignment != 0 ? ' mt-2 mt-lg-0' : '') + (index_assignment > 1 ? ' mt-lg-2' : '')} key={index_assignment}>
																										{
																											tracker_lesson_arr[data_subject.id][data_tracker.id] != null &&
																											<>
																											{
																												tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id] != null &&
																												<>
																												<div className='m-0 position-relative' style={{backgroundColor : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted == null ? '#EAEAEA' : (tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'done' ? '#7BBE74' : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'on_checking' ? '#5EB2FF' : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'need_correction' ? '#FF973C' : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'need_much_correction' ? '#CF91FF' : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'blocked' ? '#FC5A5A' : '#EAEAEA'), borderRadius : '5rem', cursor : 'pointer', padding : '0 0rem'}} onClick={()=>toDetailActivity(index_subject, index_tracker, index_assignment)}>
																													<div className='px-4'>
																														<p className='m-0' style={{fontFamily : 'PoetsenOne',  color : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted == null ? '#999999' : 'white', textOverflow : 'ellipsis', overflowX : 'hidden', fontSize : '.75rem'}}>
																															{(data_assignment.type === 'assignment' ? data_assignment.name : data_assignment.title)}
																														</p>

																													</div>
																													{
																														tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted != null &&
																														<div className='p-0 position-absolute' style={{right : '.25rem', top : '-.25rem'}}>
																															<img src={(tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'done' ? base.img_yellow_eye : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'on_checking' ? base.img_baloon : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'need_correction' ? base.img_tiny_correction : tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data === 'need_much_correction' ? base.img_eye_box : '')} style={{height : (tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data !== 'need_correction' || tracker_lesson_arr[data_subject.id][data_tracker.id][data_assignment.id].assignment_submitted.assessment_status.data !== 'done' ? '1.5rem' : '2rem')}} />
																														</div>
																													}
																												</div>
																												</>
																											}
																											</>

																										}
																									</div>
																									</>
																								))
																							}
																							</>
																						}
																						</div>
																					</div>
																				))
																			}
																			</div>
																			</td>
																			:
																			<>
																			<td colSpan={6} className='m-0 border-0'></td>
																			</>
																		}
																		
																	</tr>
																))
															}

														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>

									<div className='col-12 text-right d-flex justify-content-end'>
										<div className='row m-0'>
											{
												indicator_tracker.map((data, index)=>(
													<div className='col-auto' key={index}>
														<div className='row'>
															<div className='col-auto pr-2 d-flex align-items-center'>
																<i class="bi bi-circle-fill" style={{color : data.color, fontSize : '.75rem'}}></i>
															</div>
															<div className='col-auto p-0 d-flex align-items-center'>
																<p className='m-0'>{data.title}</p>
															</div>
														</div>
													</div>
												))
											}
										</div>
									</div>
								</div>
							</div>

							<div className='col-12 mt-4'>
								<div className='row m-0'>
									<div className='col-12 rounded bg-white'>
										<div className='row'>
											<div className='col-12 col-lg-auto p-3 p-lg-4 homeScheduleTitle' style={{background : 'linear-gradient(#FFD157, #FFB700)'}}>
												<div className='d-block d-lg-none'>
													<div className='row'>
														<div className='col col-lg-12 d-flex align-items-center'>
															<p className='m-0 text-white' style={{fontFamily : 'PoetsenOne', fontSize : '1.25rem'}}>{base.moment().format('dddd')}</p>
														</div>
														<div className='col-auto col-lg-12 d-flex align-items-center'>
															<a href='/school-schedule' type='button' className='btn btn-sm btn-danger px-4 mt-2 text-uppercase' style={{borderRadius : '5rem'}}>See All</a>
														</div>
													</div>
												</div>
												<div className='d-none d-lg-block'>
													<p className='m-0 text-white' style={{fontFamily : 'PoetsenOne', fontSize : '1.25rem'}}>{base.moment().format('dddd')}</p>
													<a href='/school-schedule' type='button' className='btn btn-sm btn-danger px-4 mt-2 text-uppercase' style={{borderRadius : '5rem'}}>See All</a>
												</div>
											</div>
											{
												schedule_arr.map((data, index)=>(
													<div className='col-12 col-lg-auto pt-2 pb-3 pb-lg-2 position-relative' key={index} style={{borderRight : '1px solid #EAEAEA'}}>
														<div className='h-100 d-flex align-items-center'>
															<div>
																<p className={'m-0' + (data.is_now ? ' text-danger' : '')} style={{fontFamily : 'PoetsenOne', fontSize : '1.25rem', color : '#999999'}}>{data.time_format}</p>
																<p className={'m-0' + (data.is_now ? ' text-danger' : '')} style={{fontFamily : 'InterBold', color : '#999999'}}>{data.type === 'event_schedule' ? data.name : data.subject.name}</p>
															</div>
														</div>
													</div>
												))
											}
										</div>

										{
											schedule_total_page > 1 &&
											<>
											<div className='d-none d-lg-block'>
												<div className='position-absolute d-flex justify-content-center align-items-center' style={{right : '-.5rem', cursor : 'pointer', background : 'linear-gradient(#FC5A5A, #D14B4B)', borderRadius : '5rem', height : '1.25rem', width : '1.25rem', top : '42.5%'}} onClick={()=>showMoreSchedule()}>
													<i className={(schedule_page === '1' ? 'fas fa-chevron-right' : 'fas fa-chevron-left') + ' text-white text-center'} style={{fontSize : '1rem'}}></i>
												</div>
											</div>
											<div className='d-block d-lg-none'>
												<div className='position-absolute d-flex justify-content-center align-items-center' style={{right : '3rem', bottom : '-1rem', cursor : 'pointer', background : 'linear-gradient(#FC5A5A, #D14B4B)', borderRadius : '5rem', height : '2rem', width : '2rem'}} onClick={()=>showMoreSchedule()}>
													<i className={(schedule_page === '1' ? 'fas fa-chevron-down' : 'fas fa-chevron-up') + ' text-white text-center'} style={{fontSize : '1rem'}}></i>
												</div>
											</div>
											</>
										}
									</div>
								</div>
							</div>

							<div className='col-12 mt-4 mt-lg-0'>
								<img src={base.img_learn} style={{height : 'auto', width : '100%'}} />
							</div>

							<div className="modal fade" id="modalAnnouncement" tabIndex="-1" aria-labelledby="modalAnnouncementLabel" aria-hidden="true">
								<div className="modal-dialog modal-dialog-centered p-4 p-lg-0-">
									<div className="modal-content rounded border-0 shadow-sm">
										<div className="modal-body rounded p-0" style={{backgroundColor : '#F8F9FE'}}>
											<div className={'row m-0'}>
												<div className='col-12 p-3'>
													<h5 className='m-0 mt-2 modalAnnouncementTitle text-primary' style={{fontFamily : 'InterBold'}}>{announcement_selected.title}</h5>
													{
														announcement_selected.body != null &&
														<div className='row'>
															<div className='col-12' dangerouslySetInnerHTML={{__html: announcement_selected.body}}>
															</div>
														</div>
													}

													<div className='row mt-2'>
														<div className='col pr-2'>
															<button className='btn btn-outline-primary w-100 rounded shadow-sm mt-4' data-dismiss="modal">Ok</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
				</>
			}



		</div>
	)
}