import { useEffect, useState } from 'react';
import Base from '../../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../../components/header';


export default function GradeBookIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_academic_year : {id : ''}})
    const [academic_year_arr, set_academic_year_arr] = useState([])
    const [selected_year, set_selected_year] = useState({id : '', name : ''})
    const [is_loading, set_is_loading] = useState(true)

    const [student_grade_arr, set_student_grade_arr] = useState([])

    const [lesson_arr, set_lesson_arr] = useState([])

    function backBtn(){
        window.location.href = '/profile'
    }

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            set_selected_year(user_data.current_academic_year)
            get_academic_year()
        }
    }, [user_data])

    useEffect(async ()=>{
        if(selected_year.id !== ''){
            get_data()
        }
    }, [selected_year])
    
    async function get_academic_year(){
        var url = '/academic-year'
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var response_data = response.data.data
                for(var x in response_data){
                    response_data[x].is_selected = false
                    if(response_data[x].id === user_data.current_academic_year.id){
                        response_data[x].is_selected = true
                    }
                }

                set_academic_year_arr(response_data)
            }
        }
    }

    function chooseYear(index){
        set_lesson_arr([])
        var student_grade = []
        student_grade = [
            {title : 'Student Progress', value : '-', is_show : true},
            {title : 'Overall Grade', value : '-', is_show : true},
            {title : 'Rank Student', value : '-', is_show : true},
            {title : 'Reward & Attendance', value : '-', is_show : true},
        ]

        var w = base.$(window).width()
        if(w < 576){
            student_grade = [
                {title : 'Overall Grade', value : '-', is_show : true},
                {title : 'Reward & Attendance', value : '-', is_show : true},
            ]
        }

        set_student_grade_arr(student_grade)
        set_is_loading(true)
        var index_selected_data = academic_year_arr[index]
        var initSelected = index_selected_data.is_selected

        for(var x in academic_year_arr){
            academic_year_arr[x].is_selected = false    
        }

        academic_year_arr[index].is_selected = !initSelected
        set_selected_year(index_selected_data)
        base.update_array(academic_year_arr, set_academic_year_arr, index_selected_data, index)
    }

    async function get_data(){
        var url = '/grade/book?academic_year_id=' + selected_year.id + '&type=current_term1'
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                if(response.data != null){
                    var response_data = response.data
                    var subject_arr = response_data.arr_subject
                    for(var x in subject_arr){
                        subject_arr[x].image_display = base.img_no_image

                        subject_arr[x].nav = '/profile/grade-book/detail?academic_year=' + selected_year.id + '&subject=' + subject_arr[x].id
                    }
                    set_lesson_arr(subject_arr)

                    
                    var student_grade = [
                        {title : 'Student Progress', value : parseFloat(response_data.completion_progres).toFixed(2) + '%', is_show : true},
                        {title : 'Overall Grade', value : response_data.total_score, is_show : true},
                        {title : 'Rank Student', value : '#' + response_data.rank, is_show : true},
                        {title : 'Reward & Attendance', value : response_data.total_point, is_show : true},
                    ]

                    var w = base.$(window).width()
                    if(w < 576){
                        student_grade = [
                            {title : 'Overall Grade', value : response_data.total_score, is_show : true},
                            {title : 'Reward & Attendance', value : response_data.total_point, is_show : true},
                        ]
                    }
                    
                    set_student_grade_arr(student_grade)
                }

                set_is_loading(false)
            }
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'Grade Book'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col d-flex align-items-center'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center justify-content-center'>
                        <div className="dropdown h-100">
                            <button className="btn bg-white rounded shadow-sm dropdown-toggle h-100 dropdownAcademyYear" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                {selected_year.name}
                            </button>
                            <div className="dropdown-menu rounded shadow-sm" aria-labelledby="dropdownMenuButton">
                                {
                                    academic_year_arr.map((data, index)=>(
                                        <a className={"dropdown-item" + (data.is_selected ? ' bg-secondary text-white' : '')} href="#!" key={index} onClick={()=>chooseYear(index)}>{data.name}</a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='col-12 mt-4 mt-lg-5'>

                <div className='row'>
                    {
                        student_grade_arr.map((data, index)=>(
                            <div className={'col-6 col-lg mb-4' + (index % 2 == 0 ? ' pr-2 pr-lg-3' : ' pl-2 pl-lg-3')} key={index}>
                                <div className="card rounded shadow-sm h-100 w-100">
                                    <div className="card-body p-3 p-lg-4">
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <h3 className='m-0 text-secondary gradeValue' style={{fontFamily : 'InterBold'}}>{data.value}</h3>
                                                <p className='m-0' style={{color : 'black'}}>{data.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div> */}

            <div className='col-12 mt-4 mt-lg-5'>

                <div className='row'>
                    {
                        is_loading ? 
                        <div className='col-12 text-center'>
                            <h4 className='m-0 d-none d-lg-block' style={{fontFamily : 'InterBold'}}>Please Wait...</h4>
                            <p className='m-0 d-block d-lg-none' style={{fontFamily : 'Inter'}}>Please Wait...</p>
                        </div>
                        :
                        <>
                            {
                                lesson_arr.length > 0 ?
                                <>
                                    {
                                        lesson_arr.map((data, index)=>(
                                            <div className={'col-12' + (index !== 0 ? ' mt-3' : '')}>
                                                <a href={data.nav} className='text-decoration-none'>
                                                <div className="card rounded shadow-sm" style={{cursor : 'pointer'}}>
                                                    <div className="card-body p-3 p-lg-4">
                                                        <div className='row'>
                                                            <div className='col-auto'>
                                                                <div className='bg-primary rounded d-flex align-items-center justify-content-center profileMenuIcon'>
                                                                    <h5 className='m-0'><i className={"text-white fas fa-book"}></i></h5>
                                                                </div>
                                                                {/* <img src={data.image_display} style={{height : '4rem', aspectRatio : 1}} /> */}
                                                            </div>
                                                            <div className='col d-flex align-items-center profileMenu'>
                                                                <h5 className='m-0 gradeBookSubjectTitle' style={{fontFamily : 'InterBold'}}>{data.name}</h5>
                                                            </div>
                                                            <div className='col-auto d-flex align-items-center justify-content-end profileMenuIcon'>
                                                                <h5 className='m-0 gradeBookSubjectArrow'><i className="fas fa-chevron-right text-primary"></i></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>
                                        ))
                                    }
                                </>
                                :
                                <div className='col-12 text-center'>
                                    <h4 className='noDataTxt m-0 d-none d-lg-block' style={{fontFamily : 'Inter'}}>No Data</h4>
                                    <p className='noDataTxt m-0 d-block d-lg-none' style={{fontFamily : 'Inter'}}>No Data</p>
                                </div>
                            }
                        </>
                    }
                </div>

            </div>

            
        </div>
    )
}