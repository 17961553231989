import React, { useEffect, useState, useRef } from 'react';
import Base from '../../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Header from '../../../components/header';
import SelectOption from '../../../components/selectOption';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function HabitChallengeForm(){
    var base = new Base()

    function useQuery(){
		const {search} = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	
	let query = useQuery()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})
    const [category_arr, set_category_arr] = useState([])
    const [selected_category, set_selected_category] = useState([])

    const [form_data, set_form_data] = useState({name : '', start_date : ''})
    const [is_disable_btn, set_is_disable_btn] = useState(false)
    
    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_category()
        }
    }, [user_data])

    async function get_category(){
        var url = '/habit/category'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data.data
                set_category_arr(response_data)
			}
		}
    }

    function backBtn(){
        window.location.href = '/profile'
    }

    function changeInput(val, type){
        if(type === 'category'){
            set_selected_category(val)
        }
        else{
            base.update_object(form_data, set_form_data, val, type)
        }
    }

    async function saveBtn(){
        var method = (query.get('type') === 'add' ? 'post' : 'put')
        var url = '/habit/tracker'

        var data = form_data
        // data.

		var response = await base.request(url, method, data)
		if(response != null){
			if(response.status == 'success'){
				window.location.href = '/profile/talent-menu'
			}
		}
    }

    return(
        <div className='row'>

            <div className='col-12 d-none d-lg-block'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col d-flex align-items-center'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center justify-content-center'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-capitalize text-center' style={{fontFamily : 'InterBold', color : 'black'}}>{query.get('type')} Habit Challenge</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-3 mt-lg-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="card rounded shadow-sm h-100 w-100">
                            <div className="card-body p-0">
                                <div className='row m-0'>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-3 pt-4 pb-5'>
                                        <form className='row m-0'>
                                            {/* <div className='col-12'>
                                                {
                                                    form_alert.message !== '' &&
                                                    <div className={"rounded alert alert-" + (form_alert.type)} role="alert">{form_alert.message}</div>
                                                }
                                            </div> */}
                                            <div className={"col-12 form-group m-0 p-0"}>
                                                <label className='text-primary labelInput'>Name Your Habit</label>
                                                <input type='text' className="form-control form-control-lg rounded editProfileInput" value={form_data.name} onChange={(e)=>changeInput(e.target.value, 'name')}/>
                                            </div>
                                            <div className={"col-12 form-group m-0 mt-3 p-0"}>
                                                <label className='text-primary labelInput'>Habit Category</label>
                                                <SelectOption
                                                    data_arr={category_arr} 
                                                    selected={selected_category}
                                                    title={'Category'}
                                                    changeInput={(value)=>changeInput(value, 'category')}
                                                />
                                            </div>

                                            <div className={"col-12 form-group m-0 mt-3 p-0"}>
                                                <label className='text-primary labelInput'>Start Date</label>
                                                <DatePicker
                                                    selected={form_data.start_date !== '' ? new Date(form_data.start_date) : ''}
                                                    onChange={date => changeInput(date, 'start_date')}
                                                    minDate={new Date()}
                                                    timeFormat="HH:mm"
                                                    dateFormat="dd MMMM yyyy"
                                                />
                                            </div>

                                            <div className='col-12 mt-4 mt-lg-5 p-0'>
                                                <button type='button' className='btn btn-primary rounded px-5 py-2 w-100' onClick={saveBtn} disabled={is_disable_btn}>Submit Habit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}