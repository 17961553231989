import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';


export default function ProfileIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, arr_reward : [], talent_balance : 0, talent_convert : '', total_reward : 0, total_ongoing_habit_tracker : 0})

    const [talent_book_arr, set_talent_book_arr] = useState([])

    const [profile_menu_arr] = useState([
        {title : 'Grade Book', desc : 'View all of your grade', icon : 'fas fa-book', nav : '/profile/grade-book'},
        {title : 'Student Profile', desc : 'View & Edit your profile', icon : 'fas fa-user-friends', nav : '/profile/student-profile/edit'},
    ])
    const [rate, set_rate] = useState('')

    const [reward_arr, set_reward_arr] = useState([])

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            set_reward_arr(user_data.arr_reward)
            set_talent_book_arr(user_data.arr_talent_transaction)
            get_rate()
        }
    }, [user_data])

    async function logout(){
        var firebaseToken = await localStorage.getItem('firebaseToken')

        var response = await base.request('/auth/logout', 'post', {token : firebaseToken})
        if(response != null){
            if(response.status == 'success'){
                localStorage.clear()
                window.location.href = '/auth/login'
            }
        }
    }

    async function get_rate(){
        var url = '/talent/rate'
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var response_data = response.data
                set_rate('Rp. ' + parseFloat(response_data.rate).toLocaleString(base.currencyFormat))
            }
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-4'>
                <div className='row'>

                    <div className='col-12 mr-0 mr-lg-4'>
                        <div className="card rounded shadow-sm">
                            <div className={"card-body p-0"}>
                                <div className={'row m-0'}>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-3 d-none d-lg-block'>
                                        <div className='row m-0'>
                                            <div className='col-12 p-4'>
                                                <div className='row'>
                                                    {
                                                        reward_arr.map((data, index)=>(
                                                            <div className='col-6 col-lg mb-2 mb-lg-0 text-center text-center p-0 student_reward' key={index}>
                                                                <div className='ratio-43 position-relative student_reward_img' style={{backgroundSize : 'contain', backgroundRepeat : 'no-repeat', backgroundImage : "url(" + base.img_reward +")"}}>
                                                                    <div className='position-absolute w-100 px-0 px-lg-5'>
                                                                        <p className='m-0 text-white'>{data.total_amount}</p>
                                                                    </div>
                                                                </div>
                                                                <p className='m-0 text-uppercase text-center mt-2 text-primary'>{data.reward.name}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 py-3 px-3 px-lg-4 mt-2' style={{borderTop : '1px solid #EAEAEA', cursor : 'pointer'}}>
                                                <div className='row'>
                                                    <div className='col d-flex align-items-center'>
                                                        <p className='m-0 text-primary'>See my Attitude Progress Chart</p>
                                                    </div>
                                                    <div className='col-auto text-right d-flex align-items-center justify-content-end'>
                                                        <h6 className='m-0'><i className="fas fa-chevron-right text-primary"></i></h6>
                                                        {/* <button className='btn btn-sm btn-outline-secondary' style={{borderRadius : '.25rem'}}>See All</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 p-3 d-block d-lg-none' style={{cursor : 'pointer'}}>
                                        <div className='row'>
                                            <div className='col-auto'>
                                                <img className='rounded' src={base.img_star} style={{width : '2rem'}} />
                                            </div>
                                            <div className='col-auto d-flex align-items-center p-0'>
                                                <div>
                                                    <p className='m-0' style={{fontSize : '.75rem'}}>Your Total Reward :</p>
                                                    <h5 className='m-0' style={{fontSize : '.75rem'}}>{user_data.total_reward} Reward</h5>
                                                </div>
                                            </div>
                                            {/* <div className='col d-flex align-items-center justify-content-end pl-0 profileMenuIcon'>
                                                <h5 className='m-0'><i className="fas fa-chevron-right text-primary"></i></h5>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='col-12 mt-3 mt-lg-5'>

                <div className='row'>
                    {
                        profile_menu_arr.map((data, index)=>(
                            <div className={'col-12 col-lg-6' + (index != 0 ? ' mt-3 mt-lg-0' : '')} key={index}>
                                <a href={data.nav} className='text-decoration-none'>
                                <div className="card rounded shadow-sm" style={{cursor : 'pointer'}}>
                                    <div className="card-body p-3 p-lg-4">
                                        <div className='row'>
                                            <div className='col-auto'>
                                                <div className='bg-primary rounded d-flex align-items-center justify-content-center profileMenuIcon'>
                                                    <h5 className='m-0'><i className={"text-white " + (data.icon)}></i></h5>
                                                </div>
                                            </div>
                                            <div className='col-auto d-flex align-items-center justify-content-center p-0 px-lg-3'>
                                                <div className='profileMenu'>
                                                    <h5 className='m-0 font-weight-normal'>{data.title}</h5>
                                                    <p className='text-uppercase m-0 d-none d-lg-block'>{data.desc}</p>
                                                </div>
                                            </div>
                                            <div className='col d-flex align-items-center justify-content-end pl-0 profileMenuIcon'>
                                                <h5 className='m-0'><i className="fas fa-chevron-right text-primary"></i></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))
                    }
                </div>

            </div>


            <div className='col-12 mt-5'>
                <div className="card rounded shadow-sm">
                    <div className={"card-body p-0"}>
                        <div className={'row m-0'}>
                            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                            <div className='col-12 p-0 p-lg-3 pt-4'>
                                <div className='row m-0'>
                                    <div className='col-12 mb-4 d-none d-lg-block'>
                                        <div className='row m-0'>
                                            <div className='col-auto p-0 d-flex align-items-center justify-content-center'>
                                                <h5 className='m-0'><i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}></i></h5>
                                            </div>
                                            <div className='col p-0 d-flex align-items-center'>
                                                <h5 className='m-0 font-weight-normal'>Talent Book</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 p-0 p-lg-3'>
                                        <div className='row m-0'>
                                            <div className='col-6 text-center d-flex align-items-center justify-content-center' style={{borderRight : '1px solid #EAEAEA'}}>
                                                <div>
                                                    <img className='profileTree_img' src={base.img_tree_talent} />
                                                    <div className='mt-3 d-block d-lg-none'>
                                                        <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                                            <h5 className='m-0' style={{color : '#999999', fontSize : '.75rem'}}>{user_data.talent_balance}</h5>
                                                        </div>
                                                        <p className='m-0 mt-2' style={{fontFamily : 'InterBold', fontSize : '.5rem', color : 'black'}}>1 Talent = {rate}</p>
                                                        <p className='rounded py-2 px-4 font-weight-bold mt-3' style={{backgroundColor : '#F0F8EF', color : '#60B158', fontSize : '.5rem'}}>Rp. {user_data.talent_convert}</p>
                                                    </div>
                                                    <div className='mt-3 d-none d-lg-block'>
                                                        <h4 className='m-0'>{user_data.talent_balance} Talent</h4>
                                                        <p className='rounded py-2 px-4 font-weight-bold mt-3' style={{backgroundColor : '#F0F8EF', color : '#60B158', fontSize : '.5rem'}}>atau Total Rp. {user_data.talent_convert}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6 mt-lg-0'>
                                                <div className='row d-none d-lg-block'>

                                                    {
                                                        talent_book_arr.length > 0 ?
                                                        <>
                                                            {
                                                                talent_book_arr.map((data, index)=>(
                                                                    <div className={'col-12' + (index !== 0 ? ' mt-3' : '')} key={index}>
                                                                        <div className='row'>
                                                                            <div className='col-auto'>
                                                                                <p className='m-0'><i className="fas fa-circle"></i></p>
                                                                            </div>
                                                                            <div className='col'>
                                                                                <p className='m-0 font-weight-bold'>{data.title}</p>
                                                                                <p className={'m-0 ' + (data.type === 'plus' ? 'text-secondary' : 'text-danger')}>{data.type === 'plus' ? '+' : '-'} {data.value} Talent</p>

                                                                                <p className='m-0 d-inline-block d-lg-none'>{base.moment().format('DD/MM/YYYY')} Pk. {base.moment().format('HH:mm')}</p>
                                                                            </div>
                                                                            <div className='col-auto text-right d-none d-lg-inline-block'>
                                                                                <p>{base.moment().format('DD/MM/YYYY')} Pk. {base.moment().format('HH:mm')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className='position-absolute-lg text-right w-100 mt-3 mt-lg-0' style={{bottom : 0}}>
                                                                <button className='btn btn-outline-secondary' style={{borderRadius : '.5rem'}}>See All</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='col-12 d-flex justify-content-center'>
                                                                <h5>No Data</h5>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                <div className='row d-block d-lg-none h-100'>
                                                    <div className='col-12 d-flex h-100 align-items-center'>
                                                        <div className='row'>
                                                            <div className='col-12 text-center'>
                                                                <h3 className='m-0 text-primary'>{user_data.total_ongoing_habit_tracker}</h3>
                                                                <p className='m-0' style={{color : 'black', fontSize : '.5rem', fontFamily : 'InterBold', lineHeight : 1.25}}>On Going Habit Challenge</p>
                                                            </div>
                                                            <div className='col-12 text-center mt-3'>
                                                                <a href='/profile/talent-menu' className='btn btn-sm btn-outline-secondary rounded shadow-sm py-2 px-3' style={{fontSize : '.5rem'}}>Check In Habit Challenge</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='col-12 mt-5 d-none d-lg-block'>
                <div className='row m-0'>
                    <div className='col-12 p-0'>
                        <img src={base.img_learn} style={{height : 'auto', width : '100%'}} />
                        {/* <div className='row'>
                            <div className='col-auto d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <h3 className='m-0' style={{color : '#575A89', fontFamily : 'PlayfairDisplay'}}>Study Until You’re Happy</h3>
                                    <p className='m-0 mt-3 mt-lg-0' style={{color : '#413F3F'}}>Lectus dignissim egestas quisque nulla. Lectus dignissim egestas quisque nulla.</p>
                                </div>
                            </div>
                            <div className='position-absolute-lg profile_msg_picture'>
                                <img src={base.img_study_2} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className='col-12 mt-5 d-none d-lg-block'>
                <div className='row m-0'>
                    <div className='col-12 col-lg-7 bg-white p-4 px-4 rounded shadow'>
                        <div className='row m-0'>
                            <div className='col-12 col-lg-auto px-4' style={{cursor : 'pointer'}} onClick={logout}>
                                <div className='row'>
                                    <div className='col-auto p-0'>
                                        <div className='d-flex align-items-center justify-content-center' style={{height : '2.25rem', width : '2.25rem', backgroundColor : '#ED757C', borderRadius : '.5rem'}}>
                                            <h4 className='m-0'><i className="bi bi-power text-white"></i></h4>
                                        </div>
                                    </div>
                                    <div className='col-auto d-flex align-items-center justify-content-center'>
                                        <p className='m-0'><b style={{color : 'black'}}>Loging Out</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg d-flex align-items-center justify-content-center justify-content-lg-end mt-3 mt-lg-0'>
                                <p className='m-0' style={{fontSize : '.75rem'}}>Version Application 1.0.1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}