import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

import io from 'socket.io-client'

export default class Base extends Component {

	// host = 'https://lms-admin.quantumtri.com'
	host = 'https://admin.christianlifeacademy-id.com'

	// socket_port = 6003
	socket_port = 6004

	base_url = this.host + '/api';
	url_image = this.host + '/image';

	timeoutAxios = 300000
	currencyFormat = 'id-ID'
	maxFileSize = 5

	axios = axios
	moment = moment
	$ = Jquery

	io = io

	constructor(props) {
		super(props)
		axios.defaults.headers.common['Content-Type'] = 'application/json'
		axios.defaults.headers.common['Accept'] = 'application/json'
		axios.defaults.timeout = this.timeoutAxios
	}

	async request(url, method = "get", data = {}, onUploadProgress = response => { }) {
		var token = await localStorage.getItem('token')
		if (token != null) {
			axios.defaults.headers.common['Authorization'] = token
		}

		try {
			var response
			if (method === 'get') {
				response = await axios.get(this.base_url + url)
			}
			else if (method === 'post') {
				response = await axios.post(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'put') {
				response = await axios.put(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'delete') {
				response = await axios.delete(this.base_url + url)
			}

			if (response.status !== 401) {
				return response.data
			}
			else {
				localStorage.clear()
				window.location.href = '/auth/login'
			}
		} catch (e) {
			setTimeout(() => {
				console.log(this.base_url + url, e)
			}, 500)
		}
	}

	// img_welcome = require('../assets/img/welcome.png')
	img_logo_text = require('../assets/img/logo.png')
	img_logo = require('../assets/img/CLA_Logo.png')
	img_logo_talent = require('../assets/img/CLA_Talent_logo.png')
	img_tree_talent = require('../assets/img/tree_talent.png')
	img_no_image = require('../assets/img/no_image_available.jpeg')
	img_no_profile = require('../assets/img/no_profile_picture.png')
	img_trophy = require('../assets/img/trophy.png')
	img_background = require('../assets/img/bg.png')

	img_borderTop_primary = require('../assets/img/borderTop-primary.png')
	img_borderLeft_primary = require('../assets/img/borderLeft-primary.png')
	img_borderTop_secondary = require('../assets/img/borderTop-secondary.png')

	img_study_1 = require('../assets/img/study_1.png')
	img_study_2 = require('../assets/img/study_2.png')
	img_study_3 = require('../assets/img/study_3.png')
	img_star = require('../assets/img/star.png')
	img_leaves = require('../assets/img/leaves.png')
	img_img_1 = require('../assets/img/img_1.png')

	img_reward_bg = require('../assets/img/reward_bg.png')
	img_reward_star = require('../assets/img/reward_star.png')
	img_reward = require('../assets/img/reward.png')

	img_baloon = require('../assets/img/baloon.png')
	img_done_icon = require('../assets/img/done_icon.png')
	img_tiny_correction = require('../assets/img/tiny_correction.png')
	img_submitted_icon = require('../assets/img/submitted_icon.png')
	img_eye_box = require('../assets/img/eye_box.png')
	img_yellow_eye = require('../assets/img/yellow_eye.png')

	img_bell = require('../assets/img/bell.png')

	img_bg_lesson = require('../assets/img/bg_lesson.png')

	img_eye = require('../assets/img/Eye.png')

	img_learn = require('../assets/img/img_learn.png')
	img_mind = require('../assets/img/img_mind.png')

	async update_array(arr, set_state, data = {}, index = 0) {
		var temp = [...arr]
		temp[index] = data
		set_state(temp)
	}

	update_object(data, set_state, variable = null, key = '') {
		var temp = JSON.parse(JSON.stringify(data))
		temp[key] = variable
		set_state(temp)
	}

	add_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.push(data)
		set_state(temp)
	}

	unshift_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.unshift(data)
		set_state(temp)
	}

	remove_array(arr, set_state, index = 0) {
		var temp = [...arr]
		temp.splice(index, 1)
		set_state(temp)
	}

	url_photo(type, file_name) {
		var image_url = this.url_image + '/' + type + '?file_name=' + file_name + '&rnd=' + moment().format('HHmmss')
		return image_url
	}

	async checkAuth() {
		var token = await localStorage.getItem('token')
		if (token == null) {
			localStorage.clear()
			window.location.href = '/auth/login'
		}
		else {
			var url = '/auth/profile'
			var response = await this.request(url)
			if (response != null) {
				if (response.status == 'success') {
					if (response.data.type.name !== 'student') {
						localStorage.clear()
						window.location.href = '/auth/login'
					}
					var firebaseToken = await localStorage.getItem('firebaseToken')
					// if(firebaseToken == null){
					// 	localStorage.clear()
					// 	window.location.href = '/auth/login'
					// }

					// await localStorage.setItem('student_access_date', response.data.current_academic_year.student_access_date)
					response.data.is_student_access = false

					var student_access_date = moment(response.data.current_academic_year.student_access_date)

					if (moment().isSameOrAfter(student_access_date)) {
						response.data.is_student_access = true
					}

					var image_display = this.url_photo('user', response.data.file_name)

					if (response.data.file_name == null) {
						image_display = this.img_no_profile
					}
					response.data.image = { image_display: image_display, image: '', original_rotation: 0, type: '' }
					return { user_data: response.data }
				}
				// else{
				// 	localStorage.clear()
				// 	window.location.href = '/auth/login'
				// }
			}
			// else{
			// 	localStorage.clear()
			// 	window.location.href = '/auth/login'
			// }
		}
	}

	async check_student_access_date() {
		var student_access_date = await localStorage.getItem('student_access_date')
		var student_access_date = moment(student_access_date)

		if (moment().isSameOrAfter(student_access_date)) {
			return true
		}
		else {
			return false
		}
	}
}