import { useEffect, useState } from 'react';
import Base from '../../../utils/base';

import Header from '../../../components/header';

export default function TalentIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})

    const [menu_arr] = useState([
        {title : 'Log Transaction Habit', nav : '#!'},
        {title : 'Setting Habit Currency', nav : '#!'},
    ])

    const [nav_button_arr] = useState([
        {icon : 'fas fa-plus-circle', type : 'add', margin : 'mr-4'},
        {icon : 'fas fa-chevron-circle-left', type : 'prev', margin : 'mr-2'},
        {icon : 'fas fa-chevron-circle-right', type : 'next', margin : 'ml-2'},
    ])
    
    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    function backBtn(){
        window.location.href = '/profile'
    }

    function navBtnAction(type){
        if(type === 'add'){
            window.location.href = '/profile/talent-menu/habit-challenge?type=add'
        }
    }

    return(
        <div className='row'>

            <div className='col-12 d-none d-lg-block'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col d-flex align-items-center'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center justify-content-center'>
                        {/* <CustomBadge title={title} type={type} /> */}
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center' style={{fontFamily : 'InterBold', color : 'black'}}>Talent Menu</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-4 mt-lg-5'>
                <div className='row'>
                    {
                        menu_arr.map((data, index)=>(
                            <div className={'col-12 col-lg-6' + (index != 0 ? ' mt-3 mt-lg-0' : '')} key={index}>
                                <a href={data.nav} className='text-decoration-none'>
                                <div className="card rounded shadow-sm" style={{cursor : 'pointer'}}>
                                    <div className="card-body p-3 p-lg-4">
                                        <div className='row'>
                                            <div className='col-auto'>
                                                <div className='bg-primary rounded d-flex align-items-center justify-content-center profileMenuIcon'>
                                                    <h5 className='m-0'><i className={"text-white fas fa-book"}></i></h5>
                                                </div>
                                            </div>
                                            <div className='col-auto d-flex align-items-center justify-content-center p-0 px-lg-3'>
                                                <div className='profileMenu'>
                                                    <h5 className='m-0'>{data.title}</h5>
                                                </div>
                                            </div>
                                            <div className='col d-flex align-items-center justify-content-end pl-0 profileMenuIcon'>
                                                <h5 className='m-0'><i className="fas fa-chevron-right text-primary"></i></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className='col-12 mt-5'>
                <div className='row'>
                    <div className='col-auto d-flex align-items-center justify-content-center'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center' style={{fontFamily : 'InterBold', color : 'black'}}>{base.moment().format('MMMM')}</p>
                        </div>
                    </div>
                    <div className='col d-flex align-items-center justify-content-end'>
                        <div className='row m-0'>
                            {
                                nav_button_arr.map((data, index)=>(
                                    <div className={'col-auto p-0 ' + data.margin} key={index}>
                                        <h5 className='m-0' style={{cursor : 'pointer'}} onClick={()=>navBtnAction(data.type)}><i className={data.icon + (data.type === 'add' ? ' text-primary' : ' text-secondary')}></i></h5>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-5'>
                <div className='row'>
                    
                </div>
            </div>

            
        </div>
    )
}