import { useEffect, useState, useMemo } from 'react';
import Base from '../../../utils/base';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from '../../../components/header';
import LoadingData from '../../../components/loading';
import NoData from '../../../components/NoData';


export default function GradeBookDetail(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})

    const [lesson_grade_arr, set_lesson_grade_arr] = useState([])
    const [subject_data, set_subject_data] = useState({id : '', name : ''})

    const [lesson_data_arr, set_lesson_data_arr] = useState([])
    const [is_loading_data, set_is_loading_data] = useState(true)

    const [activity_arr, set_activity_arr] = useState([])
    const [task_arr, set_task_arr] = useState([])

    function backBtn(){
        window.history.back()
    }

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_data()
        }
    }, [user_data])

    async function get_data(){
        var url = '/grade/book?academic_year_id=' + query.get('academic_year') + '&subject_id=' + query.get('subject')
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                if(response.data != null){
                    var response_data = response.data
                    var lesson_arr = response_data.arr_lesson

                    var lesson_arr_data = []
                    var arr_activity = []
                    var arr_task = []

                    for(let lesson of lesson_arr){
                        for(let assignment of lesson.arr_assignment){
                            var current_date = base.moment()
                            var deadline = base.moment(assignment.deadline_date)
                            assignment.status = 'Close'
                            assignment.type = 'close'
                            if(deadline.isAfter(current_date)){
                                assignment.status = 'Open'
                                assignment.type = 'open'
                            }

                        }
                        if(lesson.arr_assignment.length > 0){
                            for(var x in lesson.arr_assignment){
                                lesson.arr_assignment[x].lesson = lesson.lesson
                                arr_activity.push(lesson.arr_assignment[x])
                            }
                        }

                        var lesson_task_arr = lesson.arr_task

                        
                        if(lesson_task_arr.length > 0){
                            for(var x in lesson_task_arr){
                                lesson_task_arr[x].lesson = lesson.lesson
                                lesson_task_arr[x].average = 0
                                lesson_task_arr[x].total_score = 0
                                lesson_task_arr[x].comment = '-'
                                var skill_ctg_arr = lesson_task_arr[x].arr_skill_category
                                var total_skill = 0
                                var total_score_skill = 0
                                for(var y in skill_ctg_arr){
                                    var skill_arr = skill_ctg_arr[y].arr_skill
                                    total_skill += skill_arr.length 
                                    for(var z in skill_arr){
                                        if(skill_arr[z].grade_skill != null)
                                            total_score_skill += skill_arr[z].grade_skill.score
                                    }
                                }

                                for(var y in skill_ctg_arr){
                                    var skill_arr = skill_ctg_arr[y].arr_skill
                                    for(var z in skill_arr){
                                        if(skill_arr[z].grade_skill != null){
                                            lesson_task_arr[x].comment = (skill_arr[z].grade_skill.comment != null ? skill_arr[z].grade_skill.comment : '-')
                                            break
                                        }
                                    }
                                }

                                lesson_task_arr[x].average = parseFloat(total_score_skill / total_skill).toFixed(2)
                                lesson_task_arr[x].total_score = parseFloat(((total_score_skill / total_skill) / 5) * 100).toFixed(2)

                                arr_task.push(lesson_task_arr[x])
                            }
                        }
                    }

                    var lesson_grade = [
                        {title : 'Completion Progress', value : parseFloat(response_data.completion_progres).toFixed(2) + '%'},
                        {title : 'Overall Grade', value : response_data.total_score},
                    ]

                    set_activity_arr(arr_activity)
                    set_task_arr(arr_task)

                    // set_lesson_data_arr(lesson_arr_data)

                    set_lesson_grade_arr(lesson_grade)
                    set_subject_data(response_data.subject)

                    setTimeout(() => {
                        set_is_loading_data(false)
                    }, 1000);
                }
            }
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={(subject_data.name)+' Grade Book'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col'>
                        <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={backBtn}>
                            <h3 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h3>
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center justify-content-end'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center text-capitalize' style={{fontFamily : 'InterBold', color : 'black'}}>{subject_data.name}</p>
                        </div>
                    </div>
                </div>
            </div>

            {
                is_loading_data ?
                <LoadingData />
                :
                <div className='col-12 mt-4 mt-lg-5'>
                    {
                        activity_arr.length == 0 && task_arr.length == 0 ?
                        <NoData />
                        :
                        <>
                        <div className="card rounded shadow-sm">
                            <div className={"card-body p-0"}>
                                <div className={'row m-0'}>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-2 pt-4 mb-4'>
                                        <div className='row m-0'>

                                            {
                                                activity_arr.length > 0 &&
                                                <div className={'col-12 p-0'}>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <h4 className='m-0 gradeBookLessonTitle'>Grade</h4>
                                                        </div>
                                                        <div className='col-12 mt-3'>
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless">
                                                                    <thead>
                                                                        <tr style={{backgroundColor : '#F9FAFB', borderBottom : '1px solid #EAEAEA'}}>
                                                                            <th className=''>Lesson</th>
                                                                            <th className=''>Activity</th>
                                                                            <th className=''>Grade</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            activity_arr.map((dataActivity, indexTask)=>(
                                                                                <tr key={indexTask}>
                                                                                    <td className='td-fit-content'>{dataActivity.lesson.name}</td>
                                                                                    <td className=''>{dataActivity.name}</td>
                                                                                    <td className='td-fit-content'>
                                                                                        {
                                                                                            (dataActivity.score != null) &&
                                                                                            <span className='px-3 py-1 rounded' style={{backgroundColor : '#EBF8FF', color : '#0085FF'}}>{dataActivity.score}</span>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {
                                                task_arr.length > 0 &&
                                                <div className={'col-12 p-0' + (activity_arr.length > 0 ? 'mt-3' : 0)}>
                                                    <div className='row m-0'>
                                                        <div className='col-12'>
                                                            <h4 className='m-0 gradeBookLessonTitle'>Rubric Skill</h4>
                                                        </div>
                                                        <div className='col-12 mt-3'>
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless">
                                                                    <thead>
                                                                        <tr style={{backgroundColor : '#F9FAFB', borderBottom : '1px solid #EAEAEA'}}>
                                                                            <th className=''>Lesson</th>
                                                                            <th className=''>Activity</th>
                                                                            <th className=''>Grade</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            task_arr.map((dataTask, indexTask)=>(
                                                                                <>
                                                                                <tr key={indexTask}>
                                                                                    <td className={'text-secondary ' + (indexTask > 0 ? 'pt-4' : '')} colSpan={3}>{dataTask.lesson.name}: {dataTask.task.title + ' - ' + dataTask.task.project.name} - {dataTask.description}</td>
                                                                                </tr>
                                                                                {
                                                                                    dataTask.arr_skill_category.map((dataSkillCtg, indexSkillCtg)=>(
                                                                                        <tr key={indexSkillCtg}>
                                                                                            <td className='td-fit-content py-0'>{dataSkillCtg.name}</td>
                                                                                            <td className='py-0'>
                                                                                                <div className='row'>
                                                                                                    {
                                                                                                        dataSkillCtg.arr_skill.map((dataSkill, indexSkill)=>(
                                                                                                            <div className='col-12 mb-2'>
                                                                                                                <p className='m-0'>{dataSkill.name}</p>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className='td-fit-content py-0'>
                                                                                                <div className='row'>
                                                                                                    {
                                                                                                        dataSkillCtg.arr_skill.map((dataSkill, indexSkill)=>(
                                                                                                            <div className='col-12 mb-2' key={indexSkill}>
                                                                                                                <span className='px-3 py-1 rounded' style={{backgroundColor : '#EBF8FF', color : '#0085FF'}}>{(dataSkill.grade_skill != null ? dataSkill.grade_skill.score : 0)}</span>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }

                                                                                <tr>
                                                                                    <td className='td-fit-content py-0'>Average</td>
                                                                                    <td className='py-0'>{dataTask.average}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='td-fit-content py-0'>Score</td>
                                                                                    <td className='py-0'>{dataTask.total_score}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='td-fit-content py-0'>Notes</td>
                                                                                    <td className='py-0'>{dataTask.comment}</td>
                                                                                </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </>
                    }
                </div>
            }


            
        </div>
    )
}